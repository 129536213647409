import React, { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import { Element } from './TopScroll.styles';
import { isMobile } from '@/helpers/browser';
import ButtonCaret from '@/components/button/ButtonCaret/ButtonCaret';

export interface TopScrollProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const TopScroll = ({ children, ...props }: TopScrollProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // mount
    if (!isMobile()) {
      let observer: IntersectionObserver | null;
      let marker = document.querySelector('#marker');

      let options = {
        rootMargin: '200px 0px 0px 0px',
        threshold: 0.25,
      };

      if (marker) {
        observer = new IntersectionObserver(([entry]) => {
          if (!entry.isIntersecting) {
            ref.current?.classList.add('visible');
          } else {
            ref.current?.classList.remove('visible');
          }
        }, options);
        observer.observe(marker);
      }

      // unmount
      return () => {
        if (marker && observer) {
          observer?.unobserve(marker);
        }
      };
    }
  }, [ref]);

  return (
    <Element id="totop" ref={ref}>
      <ButtonCaret direction="up" onClick={handleClick} mobile={false} />
    </Element>
  );
};

export default TopScroll;
