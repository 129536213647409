import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme }) => {
    return css`
      position: fixed;
      bottom: 2rem;
      right: 5rem;
      text-align: initial;
      outline: none;
      user-select: none;
      transform: translateY(100px);
      transition: transform 400ms linear;
      z-index: 1000;
      &.visible {
        transform: translateY(0);
      }

      display: none;

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        display: block;
      }
    `;
  }};
`;
