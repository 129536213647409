import styled, { css } from 'styled-components';
import { ButtonCaretProps } from './ButtonCaret';

export const ButtonElement = styled.button.withConfig({
  shouldForwardProp: (prop) => !['appearence', 'direction', 'mobile'].includes(prop),
})<ButtonCaretProps>`
  ${({ theme, appearence, direction, mobile }) => {
    return css`
      ${mobile === false &&
      css`
        @media screen and (max-width: ${theme.breakpoint.sm}) {
          display: none !important;
        }
      `}

      background-color: ${theme.color.white};

      ${direction === 'up' &&
      css`
        border-top-left-radius: ${theme.radius.sm};
        border-bottom-left-radius: ${theme.radius.sm};
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3538 10.3537C13.3073 10.4001 13.2522 10.437 13.1915 10.4622C13.1308 10.4873 13.0657 10.5003 13 10.5003C12.9343 10.5003 12.8692 10.4873 12.8085 10.4622C12.7478 10.437 12.6927 10.4001 12.6463 10.3537L8 5.70678L3.35375 10.3537C3.25993 10.4475 3.13269 10.5002 3 10.5002C2.86732 10.5002 2.74007 10.4475 2.64625 10.3537C2.55243 10.2598 2.49973 10.1326 2.49973 9.99991C2.49973 9.86722 2.55243 9.73998 2.64625 9.64615L7.64625 4.64615C7.69269 4.59967 7.74783 4.56279 7.80853 4.53763C7.86923 4.51246 7.9343 4.49951 8 4.49951C8.06571 4.49951 8.13077 4.51246 8.19147 4.53763C8.25217 4.56279 8.30732 4.59967 8.35375 4.64615L13.3538 9.64615C13.4002 9.69259 13.4371 9.74774 13.4623 9.80843C13.4874 9.86913 13.5004 9.9342 13.5004 9.99991C13.5004 10.0656 13.4874 10.1307 13.4623 10.1914C13.4371 10.2521 13.4002 10.3072 13.3538 10.3537Z" fill="black" /></svg>');
      `};

      ${direction === 'down' &&
      css`
        border-top-right-radius: ${theme.radius.sm};
        border-bottom-right-radius: ${theme.radius.sm};
        background-image: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3538 6.35403L8.35375 11.354C8.30732 11.4005 8.25217 11.4374 8.19147 11.4626C8.13077 11.4877 8.06571 11.5007 8 11.5007C7.9343 11.5007 7.86923 11.4877 7.80853 11.4626C7.74783 11.4374 7.69269 11.4005 7.64625 11.354L2.64625 6.35403C2.55243 6.26021 2.49973 6.13296 2.49973 6.00028C2.49973 5.8676 2.55243 5.74035 2.64625 5.64653C2.74007 5.55271 2.86732 5.5 3 5.5C3.13269 5.5 3.25993 5.55271 3.35375 5.64653L8 10.2934L12.6463 5.64653C12.6927 5.60007 12.7479 5.56322 12.8086 5.53808C12.8693 5.51294 12.9343 5.5 13 5.5C13.0657 5.5 13.1308 5.51294 13.1915 5.53808C13.2521 5.56322 13.3073 5.60007 13.3538 5.64653C13.4002 5.69298 13.4371 5.74813 13.4622 5.80883C13.4873 5.86953 13.5003 5.93458 13.5003 6.00028C13.5003 6.06598 13.4873 6.13103 13.4622 6.19173C13.4371 6.25242 13.4002 6.30757 13.3538 6.35403Z" fill="black"/></svg>');
      `};

      background-repeat: no-repeat;
      background-position: center;

      box-shadow: ${theme.box_shadow.base};

      ${appearence === 'circle' &&
      css`
        min-width: 3rem;
        min-height: 3rem;
        border-radius: 100%;
      `};

      cursor: pointer;

      // ----------------------

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `;
  }};
`;
