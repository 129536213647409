import React, { MouseEvent, ReactNode } from 'react';
import { ButtonElement } from '@/components/button/ButtonCaret/ButtonCaret.styles';

export interface ButtonCaretProps {
  appearence?: 'circle';
  direction: 'up' | 'down';
  mobile?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
  disabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled'];
}

const ButtonCaret = React.forwardRef<HTMLButtonElement, ButtonCaretProps>(
  ({ appearence = 'circle', direction, mobile, children, ...props }: ButtonCaretProps, ref) => {
    return (
      <ButtonElement appearence={appearence} direction={direction} mobile={mobile} {...props}>
        {children}
      </ButtonElement>
    );
  }
);

ButtonCaret.displayName = 'ButtonCaret';

export default ButtonCaret;
